import axios from 'axios'
import jwtDecode from 'jwt-decode'
var jwtEncode = require('jsonwebtoken');

// instantiate axios
const Invoke = axios.create()

Invoke.url = process.env.REACT_APP_API_URL;
Invoke.config = {
  onUploadProgress: progressEvent => {
    //let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
    // do whatever you like with the percentage complete
    // maybe dispatch an action that will update a progress bar or something
  }
}

Invoke.getSesion = function () {
  return (localStorage.getItem('sesion')) ? jwtDecode(localStorage.getItem('sesion')) : null
}

Invoke.getSesionCliente = function () {
  return (localStorage.getItem('cliente')) ? jwtDecode(localStorage.getItem('cliente')) : null
}

Invoke.setSesion = function (sesion) {
  sesion = JSON.stringify(sesion)
  sesion = jwtEncode.sign(sesion, 'PORTAL')
  localStorage.setItem('sesion', sesion)
  return sesion
}

Invoke.setSesionCliente = function (sesion) {
  sesion = JSON.stringify(sesion)
  sesion = jwtEncode.sign(sesion, 'PORTAL')
  localStorage.setItem('cliente', sesion)
  return sesion
}

Invoke.getToken = function () {
  return localStorage.getItem('token')
}

Invoke.resetTime = function () {
  localStorage.setItem("dead", (60 * 1000 * 15)); // 15 Minutos de inactividad
}

Invoke.setToken = function (token) {
  localStorage.setItem('token', token)
  return token
}

Invoke.getCurrentUser = function () {
  const token = this.getToken()
  if (token) return jwtDecode(token)
  return null
}

Invoke.setDateSession = function (logInDate){
  return localStorage.setItem('logInDate', logInDate);
}

Invoke.login = function (credentials) {
  return axios.post(Invoke.url + 'login/login', credentials, Invoke.config)
    .then((serverResponse) => {
      const token = (serverResponse.data.success) ? serverResponse.data.data.token : false
      if (token) {
        // sets token as an included header for all subsequent api requests
        this.defaults.headers.common.token = this.setToken(token)
        return jwtDecode(token)
      } else {
        return serverResponse
      }
    }).catch((err) => {
      console.dir(err);
      return {message: 'No se pudo conectar al Servidor, Intentelo mas tarde.', data:{}, status: 200};
  });
}

Invoke.loginRfc = function (credentials) {
  return axios.post(Invoke.url + 'login/loginRfc', credentials, Invoke.config)
    .then((serverResponse) => {
      const token = (serverResponse.data.success) ? serverResponse.data.data.token : false
      if (token) {
        // sets token as an included header for all subsequent api requests
        this.defaults.headers.common.token = this.setToken(token)
        return jwtDecode(token)
      } else {
        return serverResponse
      }
    }).catch((err) => {
      console.dir(err);
      return {message: 'No se pudo conectar al Servidor, Intentelo mas tarde.', data:{}, status: 200};
  });
}

Invoke.logout = function () {
  localStorage.removeItem('sesion');
  localStorage.removeItem('token');
  localStorage.removeItem('dead');
  localStorage.removeItem('logInDate');
  localStorage.removeItem('showDialogNotificacion');
  localStorage.removeItem('sn_ContadoEspecial');
  // TODO - Pendiente de remover de localstore
  // facturasPendientesData
  // referenciaFacturas
  delete this.defaults.headers.common.token
  return true
}

Invoke.logout2 = function () {
  localStorage.removeItem('cliente');
  localStorage.removeItem('token');
  localStorage.removeItem('dead');
  localStorage.removeItem('logInDate');
  delete this.defaults.headers.common.token
  return true
}


Invoke.get = function (ctrl, func, params,sn_OneRoute = false) {
  return axios.get(Invoke.url + ctrl + `${sn_OneRoute? '/' + params : '/' + func +'/' + params}`, Invoke.config)
    .then(res => {
      return res;
    }).catch((err) => {
      console.dir(err);
      return {message: 'No se pudo conectar al Servidor, Intentelo mas tarde.', data:{}, status: 500};
  });
}

Invoke.post = async function (ctrl, func, params) {
  this.resetTime();
    return await axios.post(Invoke.url + ctrl + '/' + func, params, Invoke.config)
    .then(res => {
      if(res.data.success === false){
        Invoke.logout();
        window.location.reload();
        return {message: 'Usuario Sin Acceso.', data:{message:'Usuario Sin Acceso.'}, status: 200};
      } else {
        return res;
      }
    }).catch((err) => {
      console.dir(err);
      return {message: 'No se pudo conectar al Servidor, Intentelo mas tarde.', data:{message:'No se pudo conectar al Servidor, Intentelo mas tarde.'}, status: 200};
  });
}

Invoke.postHeader = function (header, ctrl, func, params) {
  this.resetTime();
  return axios({
    method: 'post',
    header: header,
    data: params,
    url: Invoke.url + ctrl + '/' + func
  })
    .then(res => {
      return res;
    }).catch((err) => {
      console.dir(err);
      return {message: 'No se pudo conectar al Servidor, Intentelo mas tarde.', data:{}, status: 500};
  });
}
// During initial app load attempt to set a localStorage stored token
// as a default header for all api requests.
Invoke.defaults.headers.common.token = Invoke.getToken()
export default Invoke
